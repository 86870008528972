import { format, isEqual, isSameDay, isSameMonth, isSameYear, parseISO } from 'date-fns';
import { enUS } from 'date-fns/locale';

export default function() {
    const formats = {
        // Sun, Sep 26, 1:00PM
        long: 'EEE, d MMM, h:mmaa',
        dayMonth: 'EEE, d MMM',
        dayMonthYear: 'EEE, d MMM y',
        numericDate: 'dd-MM-y',
        day: 'd',
        time: 'h:mm aa'
    };
    const locale = enUS;

    function getDateRange(dateStart, dateEnd, formatDateYear = formats.dayMonthYear, formatDate = formats.dayMonth, formatTime = formats.time) {
        if (isEqual(dateStart, dateEnd)) {
            return format(dateStart, formatDateYear, { locale });
        } else if (isSameDay(dateStart, dateEnd)) {
            return `${format(dateStart, formatDateYear, { locale })} - ${format(dateEnd, formatTime, { locale })}`;
        } else if (isSameMonth(dateStart, dateEnd) && isSameYear(dateStart, dateEnd)) {
            return `${format(dateStart, formatDate, { locale })} - ${format(dateEnd, formatDateYear, { locale })}`;
        } else {
            // start date + end date are different, not even the year is the same
            return `${format(dateStart, formatDateYear, { locale })} - ${format(dateEnd, formatDateYear, { locale })}`;
        }
    }

    function formatDateRange(startInput, endInput) {
        const dateStart = parseISO(startInput);

        if (endInput) {
            const dateEnd = parseISO(endInput);
            return getDateRange(dateStart, dateEnd);
        } else {
            return getDateRange(dateStart, dateStart);
        }
    }

    function formatSingleDate(input, dateFormat) {
        let parsedDate;
        if (typeof input === 'string') {
            parsedDate = parseISO(input);
        } else {
            parsedDate = input;
        }
        return format(parsedDate, dateFormat ?? formats.long, { locale });
    }

    return { formats, getDateRange, formatDateRange, formatSingleDate };
}
